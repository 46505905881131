


const foods =[
  {id: 1 , 
    name:'Pasta Sallader',
    helNamn:'Räksallad, Ost och Skinksallad, Kycklingsallad  (450g).',
    ingred:'Räkor, Ost och Skinka, Kyckling',
    spec:'Specifikation står på förpackningen',
    img:'/images/Pastaostskinka.png',
    gluten:false,
    laktos:false,
    vegan:false,
    kall:true,
    mikro:false,
    price:79

   },
   {id: 2 , 
    name:'Sallader',
    helNamn:'Räksallad, Ost och Skinksallad, Kycklingsallad , Grekisksallad (450g).',
    ingred:' Räkor, Ceasar, Tonfisk, Grekisk',
    spec:'Specifikation står på förpackningen',
    img:'/images/Raksallad.png',
    gluten:false,
    laktos:false,
    vegan:false,
    kall:true,
    mikro:false,
    price:79

   },

  {id: 3 , 
   name:'Baguette',
   helNamn:' Baguette med en variation av pålägg och delikata röror. ',
   ingred:'Kycklingröra, Skagenröra , Ost och Skinka, Köttbullar, Salami och Mozarella ',
   spec:'Specifikation står på förpackningen',
   img:'/images/baguette.png',
   gluten:false,
   laktos:false,
   vegan:false,
   kall:true,
   mikro:false,
   price:59

  },
  {id: 4 , 
   name:'Wraps',
   helNamn:'Wrap med orientaliskt tunnbröd och en variation av fyllningar.',
   ingred:'Räkor, Kyckling,Ceasar,Kebab, Rostbiff, Vegetarisk',
   spec:'Specifikation står på förpackningen',
   img:'/images/wrap-kykling.png',
   gluten:false,
   laktos:false,
   vegan:false,
   kall:true,
   mikro:false,
   price:59

  },

  {id: 5 , 
    name:'Matlådor',
    helNamn:'Olika sorters matlådor. ',
    ingred:'Köttbullar,Mozarellapasta, Lasagne',
    spec:'Specifikation står på förpackningen',
    img:'/images/wallenberg.webp',
    gluten:false,
    laktos:false,
    vegan:false,
    kall:false,
    mikro:true,
    price:79

   },
 
{id: 15 ,
  name:'Läsk', 
  helNamn:'Kall läsk och energidryck.', 
  img:'/images/lask.png',
  ingred:'Kolsyrad läsk',
  spec:'Specifikation står på förpackningen',
  gluten:false,
  laktos:false,
  vegan:false,
  kall:true,
  price:19
  
},

{id: 16 ,
  name:'Snacks', 
  helNamn:'Snacks, fyll på med energi!', 
  img:'/images/snacks.png',
  ingred:'Snacks, Bars, Choklad',
  spec:'Innehållsförtecking finns på förpackningen',
  gluten:false,
  laktos:false,
  vegan:false,
  kall:false,
  energi: true,
  price:19
  
}
]


export default foods;