import Carousel from "./Carousel";
import Hero from "./Hero";
import Food from "./Food";
import Info from "./Info";
import Text from "./Text";



const Home = () => {
  return (
    
<>
   <Hero />
   <Food />
   <Info />
   <Carousel />
   <Text />
</>

  )
}

export default Home;